import CartComponent from '@/components/dashboard/CartComponent.vue';
import Orders from '@/components/OrdersComponent.vue';
import Dashboard from '@/components/DashboardComponent.vue';
import RoutesComponent from '@/components/RoutesComponent.vue';
import SunburstWrapper from '@/components/statistic/SunburstWrapper.vue';
import SankeyWrapper from '@/components/statistic/SankeyWrapper.vue';
import SearchStats from '@/components/statistic/SearchStatistic.vue';
import RoutingViewContainer from '@/components/RoutingViewContainer.vue';
import UrlSkin from '@/components/tools/UrlSkin.vue';
import SkinUrl from '@/components/tools/SkinUrl.vue';
import AuditComponent from '@/components/AuditComponent.vue';
import EventHeatMapComponent from '@/components/monitoring/EventHeatMapComponent.vue';
import MonitoringComponent from '@/components/MonitoringComponent.vue';
import UnmarkedEventComponent from '@/components/monitoring/UnmarkedEventComponent.vue';
import SourceDetailComponent from '@/components/monitoring/SourceDetailComponent.vue';
import BrowsersStatistic from '@/components/BrowsersStatistic.vue';
import TestMonitoring from '@/components/TestMonitoring.vue';
import ScoringComponent from '@/components/scoring/ScoringComponent.vue';
import BlackListStatisticComponent from '@/components/scoring/BlackListStatisticComponent.vue';
import ScoringStaticBlackListComponent from '@/components/scoring/StaticBlackListComponent.vue';
import BlackListSettingComponent from '@/components/scoring/BlackListSettingComponent.vue';
import AppIntegration from '@/components/AppIntegration.vue';
// import UserManagementRoute from '@/modules/user-management/user-management.route';
import IAM from '@/modules/user-management/iam.route';
import ActivityLogRoute from '@/modules/activity-log/activity-log.route';
import SchemaMonitorRoute from '@/modules/schema-monitor/schema-monitor.routes';
import LabelsRoute from '@/modules/label-management/label-manegement.route';
import ValidationRulesRoute from '@/modules/validation-rules/validation-rules.route';
import DataWizardRoute from '@/modules/data-wizard/data-wizard.route';
import LocalStorage from '@/services/LocalStorage';
import layoutsDemoRoute from '@/modules/layouts-demo/layouts-demo.route';
import CockpitRoute from '@/modules/cockpit/cockpit.route';
import Welcome from '@/pages/Welcome.vue';
import ReportsComponent from '@/components/ReportsComponent.vue';
import ActivityComponent from '@/components/activity/ActivityComponent.vue';
import ReportsDataExport from '@/components/ReportRawExportComponent.vue';
import LoginComponent from '@/components/auth/LoginComponent.vue';
import LoginResetPassword from '@/components/auth/LoginResetPassword.vue';
import SignUp from '@/components/auth/SignUpComponent.vue';
import InvitationLink from '@/components/auth/InvitationLink.vue';
import ResetPassword from '@/components/auth/ResetPassword.vue';
import PrivacyPolicy from '@/pages/PrivacyPolicy.vue';
import ServiceManagementRoute from '@/modules/service-management/service-management.route';
import UserProfileRoute from '@/modules/user-profile/user-profile.route';
import TrackingEventsRoute from '@/components/tracking-events/tracking-events.route';
import TrackingParametersRoute from '@/components/tracking-parameters/tracking-parameters.route';
import TrackingSettingsRoute from '@/components/tracking-settings/tracking-settings.route';
import TrackingDataSourcesRoute from '@/components/tracking-data-sources/tracking-data-sources.route';
import DDDMRoute from '@/modules/dddm/dddm.route';
import IntegrationsRoute from '@/modules/integrations/integrations.route';
import TrackingRoute from '@/modules/tracking/tracking.route';

const DebuggerMobileComponent = () =>
    import(/* webpackChunkName: '[DebuggerMobileComponent]' */ '../components/debugger-mobile/DebuggerMobileComponent.vue');
const SdkDebuggerComponent = () =>
    import(/* webpackChunkName: '[SdkCustomeventsComponent ]' */ '../components/sdk-debugger/events/SdkCustomeventsComponent.vue');
const CollectorComponent = () => import(/* webpackChunkName: '[CollectorComponent]' */ '../components/collector/CollectorComponent.vue');
const CoveragePage = () => import(/* webpackChunkName: '[CoveragePage]' */ '@/components/tracking-coverage/CoveragePage.vue');

export default [
    // Marketing dashboard
    {
        path: '/dashboards/sales',
        name: 'dashboards-sales-redirect',
        redirect: { name: 'cart-dashboard' },
    },
    {
        path: '/dashboards/sales/carts',
        component: CartComponent,
        name: 'cart-dashboard',
        meta: {
            header: { title: 'Carts Dashboard' },
            permission: 'marketing-dashboards:carts-orders:carts',
            browserTitle: 'Carts - Carts & Orders - Marketing dashboard',
        },
    },
    {
        path: '/dashboards/sales/orders',
        component: Orders,
        name: 'orders',
        meta: {
            header: { title: 'Orders Dashboard' },
            permission: 'marketing-dashboards:carts-orders:orders',
            browserTitle: 'Orders - Carts & Orders - Marketing dashboard',
        },
    },
    {
        path: '/dashboards/traffic',
        name: 'dashboards-traffic-redirect',
        redirect: { name: 'source' },
    },
    {
        path: '/dashboards/traffic/domain-statistic',
        component: Dashboard,
        name: 'source',
        meta: {
            header: { title: 'Domain Statistic' },
            permission: 'marketing-dashboards:traffic:domain-stats',
            browserTitle: 'Domain statistic - Traffic & Search - Marketing dashboard',
        },
    },
    {
        path: '/dashboards/traffic/routes',
        component: RoutesComponent,
        redirect: { name: 'sunburst' },
        meta: {
            header: {
                tabs: [
                    { text: 'Sunburst', routeName: 'sunburst' },
                    { text: 'Sankey', routeName: 'sankey' },
                ],
            },
        },
        children: [
            {
                path: 'sunburst',
                component: SunburstWrapper,
                name: 'sunburst',
                meta: { header: { title: 'Route transition: Sunburst', tab: true } },
            },
            {
                path: 'sankey',
                component: SankeyWrapper,
                name: 'sankey',
                meta: { header: { title: 'Route transition: Sankey', tab: true } },
            },
        ],
    },
    {
        path: '/dashboards/traffic/search-statistic',
        component: SearchStats,
        name: 'search-stats',
        meta: {
            header: { title: 'Search Statistic' },
            permission: 'marketing-dashboards:traffic:search-stats',
            browserTitle: 'Search statistic - Traffic & Search - Marketing dashboard',
        },
    },

    // Marketing Tools
    {
        path: '/tools',
        component: RoutingViewContainer,
        name: 'tools',
        redirect: { name: 'skin-url' },
        children: [
            {
                path: 'url-2-skin',
                component: UrlSkin,
                name: 'url-skin',
                meta: {
                    header: { title: 'URL to Route/Skin' },
                    permission: 'marketing-tool:url-skin:route-skin',
                    browserTitle: 'URL to Route/Skin - Url / Route / Skin - Marketing tools',
                },
            },
            {
                path: 'skin-2-url',
                component: SkinUrl,
                name: 'skin-url',
                meta: {
                    header: { title: 'Route/Skin to URL' },
                    permission: 'marketing-tool:url-skin:skin-route',
                    browserTitle: 'Route/Skin to URL - Url / Route / Skin - Marketing tools',
                },
            },
        ],
    },

    // Tracking
    TrackingRoute,
    TrackingEventsRoute,
    TrackingParametersRoute,
    TrackingSettingsRoute,
    TrackingDataSourcesRoute,

    {
        path: '/tracking/coverage',
        component: CoveragePage,
        name: 'tracking-coverage',
        meta: {
            permission: 'tracking:rules:rule:view',
            browserTitle: 'Coverage - Tracking',
        },
    },
    {
        path: '/tracking/debugger-mobile',
        component: DebuggerMobileComponent,
        name: 'debugger-mobile',
        meta: {
            permission: 'msdk:connection:socket:create',
            browserTitle: 'App Visual Tagger - Tracking',
        },
    },
    {
        path: '/tracking/collector-versions',
        component: CollectorComponent,
        name: 'collector',
        meta: {
            header: { title: 'Collector Versions Config' },
            onlyServiceMaster: true,
            browserTitle: 'Collector RC - Tracking',
            permission: 'tracking:settings:config',
        },
    },
    {
        path: '/tracking/sdk-debugger',
        component: SdkDebuggerComponent,
        name: 'sdk-debugger',
        meta: {
            permission: 'msdk:connection:socket:create',
            browserTitle: 'SDK events',
        },
    },

    // Service Tools
    {
        path: '/audit',
        name: 'audit-redirect',
        redirect: { name: 'audit' },
    },
    {
        path: '/audit/orders',
        component: AuditComponent,
        name: 'audit',
        meta: {
            header: { title: 'Orders Audit' },
            permission: 'service-tool:audit:orders',
            browserTitle: 'Orders - Audit - Service Tools',
        },
    },
    {
        path: '/audit/event-monitoring',
        component: EventHeatMapComponent,
        name: 'event-monitoring',
        meta: { header: { title: 'Events Stat' }, permission: 'service-tool:audit:event', browserTitle: 'Event - Audit - Service Tools' },
    },
    {
        path: '/audit/monitoring',
        component: MonitoringComponent,
        name: 'monitoring',
        meta: {
            header: { title: 'Monitoring' },
            permission: 'service-tool:audit:monitoring',
            browserTitle: 'Monitoring - Audit - Service Tools',
        },
    },
    {
        path: '/audit/monitoring/unmarked-event',
        component: UnmarkedEventComponent,
        name: 'monitoring-unmarked',
        meta: { header: { title: 'Unmarked event list' }, permission: 'service-tool:audit:monitoring' },
    },
    {
        path: '/audit/monitoring/source',
        component: SourceDetailComponent,
        name: 'monitoring-source',
        meta: { permission: 'service-tool:audit:monitoring' },
    },
    {
        path: '/audit/browsers-statistic',
        component: BrowsersStatistic,
        name: 'browsers-statistic',
        meta: {
            header: { title: 'Browsers Statistic' },
            permission: 'service-tool:audit:browser-statistic',
            browserTitle: 'Browsers Statistic - Audit - Service Tools',
        },
    },
    {
        path: '/audit/test-monitoring',
        component: TestMonitoring,
        name: 'test-monitoring',
        meta: {
            header: { title: 'Test Monitoring' },
            permission: 'service-tool:audit:test-monitoring',
            browserTitle: 'Test Monitoring - Audit - Service Tools',
        },
    },
    {
        path: '/scoring',
        name: 'scoring-redirect',
        redirect: { name: 'scoring' },
    },
    {
        path: '/scoring/statistics',
        component: ScoringComponent,
        name: 'scoring',
        meta: {
            header: { title: 'Scored Traffic' },
            permission: 'service-tool:scoring:statistics',
            browserTitle: 'Statistics - Scoring - Service Tools',
        },
    },
    {
        path: '/scoring/list/:list/:id',
        component: BlackListStatisticComponent,
        name: 'scoring-list',
        meta: {
            header: { title: 'Black list' },
            permission: 'service-tool:scoring:settings',
            browserTitle: 'Black list - Scoring - Service Tools',
        },
    },
    {
        path: '/scoring/static-list',
        component: ScoringStaticBlackListComponent,
        name: 'static-list',
        meta: {
            header: { title: 'Static IPs' },
            permission: 'service-tool:scoring:static-ips',
            browserTitle: 'Static list - Scoring - Service Tools',
        },
    },
    {
        path: '/scoring/block-list',
        component: BlackListSettingComponent,
        name: 'list-settings-list',
        meta: {
            header: { title: 'List Settings' },
            permission: 'service-tool:scoring:settings',
            browserTitle: 'List settings - Scoring - Service Tools',
        },
    },

    // Service Configuration
    {
        path: '/config/service-tokens',
        component: AppIntegration,
        name: 'service-tokens',
        meta: {
            header: { title: 'Application API keys management' },
            permission: 'iam:api-tokens:token',
            browserTitle: 'Service Tokens - IAM - Data Platform',
        },
    },
    // UserManagementRoute,
    ActivityLogRoute,
    SchemaMonitorRoute,

    // Labels Management
    LabelsRoute,

    // Validation Rules
    ...ValidationRulesRoute,

    // DDDM Tools
    DDDMRoute,

    // Data Wizard
    ...DataWizardRoute,

    // Layout Demo
    ...(LocalStorage.get('templates') ? [layoutsDemoRoute] : []),

    // Cockpit
    CockpitRoute,

    // IAM
    IAM,

    // Other
    {
        path: '/',
        redirect: { name: 'welcome' },
        name: 'default',
        meta: { allowForMaster: true, browserTitle: 'Welcome' },
    },
    {
        path: '/welcome',
        component: Welcome,
        name: 'welcome',
        meta: { allowForMaster: true, browserTitle: 'Welcome' },
    },
    {
        path: '/reports/raw/export',
        component: ReportsComponent,
        name: 'reports-export',
        meta: { header: { title: 'Reports list' } },
    },
    {
        path: '/activities',
        component: ActivityComponent,
        name: 'activities',
        meta: { header: { title: 'Activities' } },
    },
    {
        path: '/reports/raw/export/create',
        component: ReportsDataExport,
        name: 'reports-export-create',
        meta: { header: { title: 'Create new export' } },
    },
    {
        path: '/login',
        component: LoginComponent,
        name: 'login',
        meta: { requiresGuest: true, browserTitle: 'Login' },
    },
    {
        path: '/login/reset-password',
        component: LoginResetPassword,
        name: 'login-reset-password',
        meta: { requiresGuest: true, browserTitle: 'Reset password' },
    },
    {
        path: '/sign-up',
        component: SignUp,
        name: 'sign-up',
        meta: { requiresGuest: true, browserTitle: 'Sing Up' },
    },
    {
        path: '/accept-invitation/:token',
        component: InvitationLink,
        name: 'invitation-link',
        meta: { requiresGuest: true, browserTitle: 'Sing Up' },
    },
    {
        path: '/reset-password/:token',
        component: ResetPassword,
        name: 'reset-password',
        meta: { requiresGuest: true, browserTitle: 'Reset password' },
    },
    {
        path: '/vt-privacy-policy',
        component: PrivacyPolicy,
        name: 'vt-privacy-policy',
        meta: { noAuthCheck: true, isPublic: true, browserTitle: 'Privacy policy for Visual Tagger browser extension' },
    },
    ServiceManagementRoute,
    UserProfileRoute,

    // 3-rd party services integrations
    ...IntegrationsRoute,
];
